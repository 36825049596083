export const tickets = {
    create: {
      url: "tickets/create",
      method: "POST",
      data: null,
    },
    update: {
      url: "tickets/update/",
      method: "PUT",
      id: null,
      data: null,
    },
    get: {
      url: "admin/ticket/getAllTickets",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "tickets/delete/",
      method: "DELETE",
      id: null,
    },
    closeTicket: {
      url: "admin/ticket/closeTicket/",
      method: "GET",
      id: null,
    },
    reOpenTicket: {
      url: "admin/ticket/reOpenTicket/",
      method: "GET",
      id: null,
    },
    getTicketById: {
      url: "admin/ticket/getById/",
      method: "GET",
      id: null,
    },
    addComment: { 
      url: "admin/ticket/addComment/",
      method: "PUT",
      id: null,
      data: null,
    }
  };