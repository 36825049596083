<template>
  <Layout>
    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-tabs>
              <b-tab title="Open" @click="tabChange(0)">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                           @input="reloadDataLimit"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="ticketsData"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    tbody-tr-class="rowClass"
                    @row-clicked="showTicket"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                    <template #cell(message)="row">
                      {{ row.item.message }}
                    </template>
                    <template #cell(user)="row">
                      {{
                        row.item.user == null ? "ADMIN" : row.item.user.email
                      }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.value).format("lll") }}
                    </template>
                    <template #cell(actions)="row">
                      <b-dropdown>
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item @click="closeTicket(row.item)"
                          >Close</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </b-table>
                  <b-modal id="bv-modal-example" centered hide-footer>
                    <template #modal-title>
                      {{ selectedTicket && selectedTicket.message }}
                    </template>
                    <div class="d-block text-center">
                      <div v-if="checkCommentsLength(selectedTicket)">
                        <ul
                          v-for="comment in selectedTicket.comments"
                          :key="comment._id"
                        >
                          <li>{{ comment.message }}</li>
                        </ul>
                      </div>
                      <div v-else>
                        <p>No comments</p>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </b-tab>
              <b-tab title="Closed" @click="tabChange(1)">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="ticketsData"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    tbody-tr-class="rowClass"
                    @row-clicked="showTicket"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                    <template #cell(message)="row">
                      {{ row.item.message }}
                    </template>
                    <template #cell(user)="row">
                      {{
                        row.item.user == null ? "ADMIN" : row.item.user.email
                      }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.value).format("lll") }}
                    </template>
                    <template #cell(actions)="row">
                      <b-dropdown>
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item @click="reOpenTicket(row.item)"
                          >Re Open</b-dropdown-item
                        >
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </b-tab>
            </b-tabs>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="updatePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { tickets } from "@/config/api/tickets";
import Layout from "@/views/layouts/main";
import router from "@/router";
export default {
  components: {
    Layout,
  },
  props: ["reload"],
  data() {
    return {
      ticketsData: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      selectedTicket: null,
      ticketStatus: 0,
      fields: [
        { key: "message", sortable: false },
        { key: "user", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.getAllTickets();
    
  }, 
  computed: {
    rows() {
      return this.totalRows;
    }, 
  },
  watch: {
    reload(val) {
      if (val) {
        this.getAllTickets();
      }
    },
  },

  methods: {
    reloadDataLimit() {
      this.getAllTickets(1, this.ticketStatus, this.perPage);
    },
    closeTicket(data) {
      this.currentPage = 1;
      const api = tickets.closeTicket;
      api.id = data._id;
      console.log(api);
      this.generateAPI(api)
        .then(() => {
          this.getAllTickets(1, this.ticketStatus, this.perPage);
          this.$bvToast.toast("Ticket Closed!", {
            title: "Ticket Closed!",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Something went wrong!", {
            title: "Unable to update the status, Try again!",
            variant: "danger",
            solid: true,
          });
        });
    },
    reOpenTicket(data) {
      this.currentPage = 1;
      const api = tickets.reOpenTicket;
      api.id = data._id;
      console.log(api);
      this.generateAPI(api)
        .then(() => {
          this.getAllTickets(1, this.ticketStatus, this.perPage);
          this.$bvToast.toast("Ticket Re Opened!", {
            title: "Ticket Re Opened!",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Something went wrong!", {
            title: "Unable to update the status, Try again!",
            variant: "danger",
            solid: true,
          });
        });
    },
    tabChange(value) {
        this.currentPage = 1;
        this.ticketStatus = value;
        this.getAllTickets(1, value, this.perPage);
    },
    updatePage(page) {
      this.getAllTickets(page, this.ticketStatus, this.perPage);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllTickets(page = 1, status = 0, limit = 10) {
      console.log("per page = " + limit);
      this.isBusy = true;
      this.ticketsData = [];
      const api = tickets.get;
      api.params = { page: page, status: status, limit: limit};
      this.generateAPI(api)
        .then((res) => {
          this.ticketsData = res.data.tickets;
          this.totalRows = res.data.count;
          console.log("total rows = " + this.totalRows);
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.isBusy = false;
          this.$emit("dataLoaded");
        });
    },
    showTicket(row) {
      // console.log(row.message);

      this.selectedTicket = row;
      router.push({ name: "TicketComments", params: { ticket_id: this.selectedTicket._id } });
      this.$bvModal.show("bv-modal-example");
    },
    checkCommentsLength(data) {
      if (data == null) {
        return false;
      }
      if ("comments" in data) {
        // console.log("Comments not null");
        if (data.comments.length > 0) {
          return true;
        }
      } else {
        // console.log("Comments null");
        return false;
      }
    },
  },
};
</script>
<style>
.dropdown-item {
  color: #5d6071;
}
.rowClass {
  cursor: pointer;
}
.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>
